<template>
	<v-card flat class="pa-1 wr_grey_1">
		<NoData
			v-if="!list || !list.length"
			heading="app.no_maps_yet"
			@init="dialog = true"
		></NoData>

		<div v-else>
			<AppListInBoxTheme
				img="mindmap"
				:list="list"
				@explore="explore"
				@delete="deleteMap"
			></AppListInBoxTheme>

			<v-btn
				large
				fab
				class="mb-10"
				color="success"
				:style="styleObj"
				@click="dialog = true"
			>
				<v-icon large>$vuetify.icons.values.plus</v-icon>
			</v-btn>
		</div>

		<MapNameDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@name-created="create"
		></MapNameDialog>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NoData from "@/components/shared/NoData.vue";
import { v4 as uuidv4 } from "uuid";
import AppListInBoxTheme from "@/components/ui/AppListInBoxTheme.vue";
import MapNameDialog from "@/components/shared/MindmapNameDialog.vue";
import { generateRandomString } from "@/utils/helpers";

export default {
	name: "StandardMindmapList",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		NoData,
		AppListInBoxTheme,
		MapNameDialog,
	},

	mounted() {
		this.getList();
	},

	computed: {
		...mapState({
			list: (state) => state.standardMindmap.list,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
		}),

		styleObj() {
			return {
				position: "fixed",
				bottom: "70px !important",
				[this.$vuetify.rtl ? "left" : "right"]: "60px",
			};
		},
	},

	methods: {
		async getList() {
			try {
				this.$loader.start();

				await this.$store.dispatch("standardMindmap/list", {
					category: this.$defines.TOOLS_CATEGORY,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async create(map_name, cb) {
			try {
				await this.$store.dispatch("standardMindmap/create", {
					root_node_id: uuidv4(),
					category: this.$defines.TOOLS_CATEGORY,
					name: map_name,
					data: {
						linkData: {},
						nodeData: {
							id: generateRandomString(),
							root: true,
							topic: this.$t("app.root_node"),
						},
					},
				});

				cb();

				this.dialog = false;
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},

		explore(map_id) {
			try {
				this.$router.push({
					name: this.isMentor
						? "MentorStandardMindmapExplore"
						: "StandardMindmapExplore",
					params: {
						id: map_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteMap(map_id) {
			try {
				this.$loader.start();

				await this.$store.dispatch("standardMindmap/delete", {
					id: map_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
