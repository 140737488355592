<template>
	<div>
		<v-card
			height="200"
			width="270"
			v-for="(item, index) in list"
			:key="index"
			class="ma-3 d-inline-block"
		>
			<v-card-text>
				<v-row no-gutters>
					<v-col sm="4">
						<v-img
							:src="require(`@/assets/images/${img}.png`)"
							width="70"
						></v-img>
					</v-col>
					<v-col
						align="center"
						class="px-2 font-weight-bold subtitle-1 black--text text-truncate"
					>
						{{ item.name }}
					</v-col>
				</v-row>
			</v-card-text>

			<v-footer absolute bottom>
				<v-row>
					<v-col>
						<v-icon
							color="error"
							class="mt-1"
							@click="prepareDeletion(item[id_type])"
							>$vuetify.icons.values.delete</v-icon
						>
					</v-col>
					<v-col class="text-end">
						<v-btn
							fab
							depressed
							x-small
							color="success"
							@click="$emit('explore', item[id_type])"
						>
							<v-icon>{{
								$vuetify.icons.values[
									$vuetify.rtl ? "left_chevron" : "right_chevron"
								]
							}}</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-footer>
		</v-card>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="dialog"
			:dialog="dialog"
			@cancel="dialog = false"
			@confirm="$emit('delete', selected_id), (dialog = false)"
		></TheConfirmation>
	</div>
</template>

<script>
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "AppListInBoxTheme",

	props: {
		list: {
			required: true,
			type: Array,
		},

		img: {
			required: true,
		},

		id_type: {
			default: "id",
		},
	},

	data() {
		return {
			dialog: false,
			selected_id: null,
		};
	},

	components: {
		TheConfirmation,
	},

	methods: {
		prepareDeletion(map_id) {
			try {
				this.selected_id = map_id;
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
