<template>
	<List></List>
</template>

<script>
import List from "@/components/shared/tools/standardMindmap/List.vue";

export default {
	name: "StandardMindmapListPage",

	components: {
		List,
	},
};
</script>
